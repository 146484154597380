<template>
        <div class="h-auto flex flex-col w-full overflow-hidden">
            <loader :loading="loading" />
            <div v-if="!loading" class="h-full overflow-auto no-scrollbar pb-6">
                <div v-for="(value, key) in data" :key="key">
                    <pipeline-circle :row="value" :title="key" />
                </div>
            </div>
        </div>
</template>

<script>

    import { state } from '@/store';
    const ChartGauge  = () => import('@/charts/ChartGauge.vue');
    const PipelineCircle = () => import('@/components/Mobile/Pipeline/PipelineCircle.vue')

    export default {
        components: {
            ChartGauge,
            PipelineCircle
        },
        data() {
            return {
                data: { },
                loading: false
            }
        },
        computed: {
            params() {
                let params = state.globalParams
                if (this.$route.params.catId) {
                    params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
                }
                return params
            },
            legendColors() { return this.$legendColorsBySector(this.data.sectors) }
        },
        watch: {
            params() { this.getBalls() }
        },
        methods: {
            getBalls() {
                this.axios.get('pipeline/service_line', { params: this.params }).then(response => {
                    this.data = response.data.data.circles;
                    this.loading = false
                })
            },
            preload() {
                this.loading = true;
            },
        },
        mounted() {
            this.preload();
            if(this.params) {
                this.getBalls();
            }
        }
    }
</script>
